<template>
  <v-container fluid grey lighten-3 pa-10>
    <h2>Update FLASH COD Remittance</h2>
    <v-row>
      <v-col>
        <v-text-field
          v-model="urlFlashCod"
          label="กรอก URL COD"
          append-icon="mdi-link"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="subtitle-1 mb-2">{{ codInformation }}</h3>
        <v-card>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="dataTrack"
            item-key="tracking"
            :loading="dataTableLoading"
            hide-default-footer
            disable-pagination
            show-select
            disable-filtering
            disable-sort
            mobile-breakpoint="200"
          >
            <!-- table top section -->
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-4" light height="55">
                <v-badge size="18" color="yellow accent-4">
                  <template v-slot:badge>
                    <span class="black--text">{{ selected.length }}</span>
                  </template>
                  เลือก
                </v-badge>
                <v-divider class="mx-7" inset vertical></v-divider>
                {{totalRecord}}
                <v-spacer></v-spacer>
                <v-btn
                  color="yellow darken-1"
                  @click="doUpdate"
                  light
                  class="mb-1 mr-1"
                  small
                >
                  <v-icon class="mr-1">mdi-database</v-icon>
                  Update COD
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:item="{ item, select, isSelected, index }">
              <tr :key="index">
                <td>
                  <v-simple-checkbox
                    @input="select"
                    :value="isSelected"
                    :ripple="false"
                  ></v-simple-checkbox>
                </td> 

                <td class="yellow lighten-4">
                  {{ item.amount | currency("฿", 2) }}
                </td>
                <td class="red lighten-4"><span v-if="item.tracking">{{ item.tracking }}</span></td>
                <td class="blue lighten-5 caption">
                  <span v-if="item.inOrder" class="green--text"
                    ><v-icon color="green"
                      >mdi-checkbox-marked-circle</v-icon
                    ></span
                  >
                  <span class="red--text" v-else
                    ><v-icon color="red">mdi-close-circle</v-icon></span
                  >
                  {{ item.message }}
                </td>
                <td class="blue lighten-5">
                  <span v-if="item.data.tracking">{{ item.data.tracking }}</span>
                </td>
                <td class="blue lighten-5">
                  {{ item.data.orderid | orderIdFormat }} | {{ item.data.name }}
                </td>
                <td class="blue lighten-5">
                  {{ item.data.amount | currency("฿", 0) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import httpClient from "@/services/httpClient";
import api from "@/services/api";
import _ from "lodash";

export default {
  name: "CodTracking",
  data() {
    return {
      selected: [],
      urlFlashCod: "",
      data: [],
      dataTrack: [],
      codInfo: {
        dateRef: "",
        paidRef: ""
      },
      dataTableLoading: false,
      dataUpadted: [],
      headers: [
        {
          text: "Amount",
          value: "amount",
          class: "yellow lighten-1"
        },
        { text: "Tracking no", value: "track", class: "red lighten-1" },
        { text: "ข้อมูลระบบ", value: "updated", class: "blue lighten-4" },
        { text: "Tracking in DB", value: "tracking2", class: "blue lighten-4" },
        { text: "Name in DB", value: "name2", class: "blue lighten-4" },
        { text: "Amount in DB", value: "amount2", class: "blue lighten-4" }
      ]
    };
  },
  mounted() {
    //this.getData();
  },
  methods: {
    getData: _.debounce(async function() {
      let isUrl = this.urlFlashCod.indexOf(
        "https://api.flashexpress.com/web/ka/cod"
      );
      if (_.trim(this.urlFlashCod) != "" && isUrl > -1) {
        try {
          this.dataTableLoading = true;
          let result = await httpClient.get(this.urlFlashCod);
          let htmldata = result.data;
          let tracking_matches = htmldata.match(
            /\<td\>TH([a-z0-9]{11,12})\<\/td\>/gi
          );

          // let orderid_matches = htmldata.match(
          //   /\<td\>([a-z\s]*)([0-9]{5})\<\/td\>/gi
          // );
          let amount_matches = htmldata.match(
            /\<td\>([0-9]{1,5})\.([0-9]{2})\<\/td\>/g
          );


          let codid_matches = htmldata.match(
            /class=\"f\-left\"\>(COD)-([a-z0-9]{14})\<\/span\>/gi
          );
          let codid_ref = codid_matches[0].replace(
            /class=\"f\-left\"\>|\<\/span\>|\s/g,
            ""
          );

          let coddate_paid = htmldata.match(
            /class=\"f-right\"\>([0-9]{4})-([0-9]{2})-([0-9]{2})\<\/span\>/g
          );
          let coddate_ref = coddate_paid[0].replace(
            /class=\"f-right\"\>|\<\/span\>/g,
            ""
          );

          this.codInfo = {
            dateRef: coddate_ref,
            paidRef: codid_ref
          };

          let trackings = _.map(tracking_matches, regDetectData);
          // let orderids = _.map(orderid_matches, regDetectData);
          let amounts = _.map(amount_matches, regDetectData);
          let datatrack = _.map(trackings, (track, i) => {
       
              return {
                inOrder: false,
                message: 'ไม่พบ',
                updated: false,
                responed: null,
                tracking: track,
                amount: amounts[i]
              }
          })

          this.data = datatrack;
          this.checkTrackToOrderCOD();
          
        } catch (error) {
          console.log("URL is invalid");
        }

        function regDetectData(valString) {
          return valString.replace(/\<td\>|\<\/td\>/g, "");
        }
      } else {
        console.log("URL API ไม่ถูกต้อง");
        this.dataTableLoading = false;
      }
    }, 1000),
    async checkTrackToOrderCOD() {
      if (this.data.length > 0) {
          this.dataTableLoading = true;

          let formData = new FormData();
          formData.append("items", JSON.stringify(this.data));

          let result = await api.getOrderByTrackingCODRemittanceFlash(formData);
          if (result.data.result == "ok") {
            _.delay(() => {
              this.dataTrack = JSON.parse(result.data.message);
              this.dataTableLoading = false;
            }, 1000);
          }
          
      }
    },
    async doUpdate() {
      if (confirm("คุณต้องการ Update COD เข้าระบบใช่หรือไม่?")) {
        this.dataTableLoading = true;
        if (this.selected.length > 0) {
        

          let formData = new FormData();
            formData.append("items_selected", JSON.stringify(this.selected));
            formData.append("codref", _.trim(this.codInfo.paidRef));
            formData.append("dateref", _.trim(this.codInfo.dateRef));

            let result = await api.updateCodRemittanceFlash(formData);
            if (result.data.result == "ok") {
              let data = JSON.parse(result.data.message);
              let initdata = this.dataTrack;

              _.forEach(data, (r, i) => {
                let trackindex = _.findIndex(initdata, { tracking: r.tracking });
                if (trackindex > -1) {
                  initdata[trackindex].data.cod_status = r.data.cod_status;
                  initdata[trackindex].message = r.message;
                  initdata[trackindex].updated = r.updated;
                  initdata[trackindex].responed = r.responed;

                }
              });

              this.dataTrack = [];
              _.delay(() => {
                this.dataTrack = initdata;
                this.dataTableLoading = false;
              }, 2000);
            }



          // const myFunctionData = () => {
          //   return new Promise((resolve, reject) => {
          //     let resData = _.cloneDeep(this.data);
          //     _.forEach(this.selected, async (data, index) => {
          //       let formData = new FormData();
          //       // formData.append("id", _.trim(data.orderid));
          //       formData.append("tracking", _.trim(data.tracking));
          //       formData.append("amount", _.trim(data.amount));
          //       formData.append("codref", _.trim(this.codInfo.paidRef));
          //       formData.append("dateref", _.trim(this.codInfo.dateRef));

          //       //let c = { ...data };
          //       let result = await api.updateCODOrderByTracking(formData);
          //       if (result.data.result == "ok") {
          //         if (result.data.message > 0) {
          //           let dataindex = _.findIndex(resData, {
          //             tracking: data.tracking
          //           });
          //           if (dataindex > -1) {
          //             resData[dataindex].updated = true;
          //             resData[dataindex].message = "อัพเดต: succeed";
          //             resData[dataindex].responed = true;
          //           }
          //         } else {
          //           let dataindex = _.findIndex(resData, {
          //             tracking: data.tracking
          //           });
          //           if (dataindex > -1) {
          //             resData[dataindex].updated = false;
          //             resData[dataindex].message = "อัพเดต: failed";
          //             resData[dataindex].responed = false;
          //           }
          //         }
          //       }
          //     });
          //     resolve({ result: resData });
          //   });
          // };

          // let rc = await myFunctionData();
          // this.data = [];
          // _.delay(() => {
          //   this.data = rc.result;
          //   this.dataTableLoading = false;
          // }, 1000)


        } else {
          console.log("no selected data.");
        }
      }
    }
  },
  watch: {
    urlFlashCod: {
      handler(newValue, oldValue) {
         this.selected = [];
        if (newValue != "") {
          this.getData();
        } else {
          this.dataTableLoading = false;
          this.data.splice(0, this.data.length);
          this.dataTrack.splice(0, this.dataTrack.length);
        }
      }
    }
  },
  computed: {
    codInformation() {
      return (
        "Recipient ID: " +
        this.codInfo.paidRef +
        " Date: " +
        this.codInfo.dateRef
      );
    },
    totalRecord() {
      let total = this.dataTrack.length; 
      let found = _.filter(this.dataTrack, { inOrder:true });
      let rSucceed = _.filter(this.dataTrack, {responed:true});
      let rFailed = _.filter(this.dataTrack, {responed:false});
      return `Record: ${total} Founds: ${found.length} Succeed: ${rSucceed.length} Failed: ${rFailed.length}`;
    },
  },
  beforeDestroy: function() {
    delete this.data;
    delete this.dataTrack;
    delete this.selected;
    delete this.dataUpadted;
  }
};
</script>

<style></style>
